
















import { Component } from "vue-property-decorator";
import Request from "../../services/request";
import env from "../../services/env";
// import { format,parseISO } from "date-fns";

@Component
export default class Flow extends Request {
  flowUrl = env.flowUrl;
  mounted() {
    this.flowUrl = env.flowUrl;
  }
  iframeLoaded() {
    const iframe = this.$refs.typebotIframe;
    if (iframe instanceof HTMLIFrameElement) {
      const iframeWindow = iframe.contentWindow;
      if (iframeWindow) {
        iframeWindow.postMessage(
          {
            type: "CUSTOM_MESSAGE",
            content: "Olá, TypeBot!",
          },
          this.flowUrl
        );

        window.addEventListener("message", (event) => {
          if (event.origin === this.flowUrl) {
            const receivedMessage = event.data;
            if (receivedMessage.type === "RESPONSE") {
              // Fazer algo com a resposta recebida do TypeBot
            }
          }
        });
      }
    }
  }
}
