











































import { Component } from "vue-property-decorator";
import GroupInput from "./GroupInput.vue";
import { TPageHeader } from "@matrixbrasil/matrix-lib-utils-frontend/src/types/PageHeader";
import Request from "../../../services/request";
import { translate } from "../../../plugins/i18n/index";
import { paths } from "../../../services/apiPaths";
import ConfirmDelete from '../../../components/ConfirmDelete.vue'
import {
  BaseBreadcrumb,
} from "@matrixbrasil/matrix-lib-utils-frontend/src/components";
import { List } from "@matrixbrasil/matrix-lib-crud-frontend/src/views";

@Component({
  components: {
    GroupInput,
    List,
    ConfirmDelete,
    BaseBreadcrumb,
  },
})
export default class GroupList extends Request {
  productList: any = [];
  productId: any = null;
  rerender = 1;
  path = paths.heppyInvoicePlans;

  page = {
    title: `${translate("group")}`,
    icon: null,
  };

  breadcrumbs = [
    {
      text: `${translate("group")}`,
      disabled: true,
    },
  ];

  header = [
    { text: `${translate("planName")}`, align: "start", value: "name" },
    { text: `${translate("price")}`, align: "center", value: "price" },
    {
      text: `${translate("dateRegister")}`,
      align: "center",
      value: "updatedAt",
    },
    { text: `${translate("active")}`, align: "center", value: "active" },
    {
      text: `${translate("actions")}`,
      value: "actions",
      align: "center",
      sortable: false,
      width: "15%",
    },
  ];
  pageHeader: TPageHeader = {
    mainIcon: "mdi-account-group-outline",
    titlePage: `${translate("group")}`,
    buttonHeader: "true",
    buttonIcon: "mdi-plus",
    method: "openInputNew",
    crudDetail: "false",
  };
  list: any = [];

  async loadData() {
    this.path = this.paths.heppyInvoicePlans;
   
  }
  async mounted() {
    this.loadData();
  }
}
