


























































































































import { Component } from "vue-property-decorator";
import Request from "../../services/request";
import { translate } from "../../plugins/i18n/index";
import { paths } from "../../services/apiPaths";
import ConfirmDelete from "../../components/ConfirmDelete.vue";
import { BaseBreadcrumb } from "@matrixbrasil/matrix-lib-utils-frontend/src/components";
import { List } from "@matrixbrasil/matrix-lib-crud-frontend/src/views";
import * as XLSX from "xlsx";
@Component({
  components: {
    List,
    ConfirmDelete,
    BaseBreadcrumb,
  },
})
export default class SaleActionsList extends Request {
  productList: any = [];
  productId: any = null;
  rerender = 1;
  path = paths.heppyInvoicePlans;
  dialog = false;
  itemHeader = "";
  page = {
    title: `${translate("saleActions")}`,
    icon: null,
  };
  itemsDownload: any = [];
  headers: any = [
    {
      id: 1,
      title: translate("name"),
      value: "name",
    },
    {
      id: 2,
      title: translate("email"),
      value: "email",
    },
    {
      id: 4,
      title: translate("phone"),
      value: "phone",
    },
  ];
  items = [
    {
      id: 1,
      name: "Jason Oner",
      email: "teste@teste.com",
      phone: "31979797979",
    },
    {
      id: 2,
      name: "Travis Howard",
      email: "teste@teste.com",
      phone: "31979797979",
    },
    {
      id: 3,
      name: "Ali Connors",
      email: "teste@teste.com",
      phone: "31979797979",
    },
    {
      id: 4,
      name: "Cindy Baker",
      email: "teste@teste.com",
      phone: "31979797979",
    },
  ];
  breadcrumbs = [
    {
      text: `${translate("saleActions")}`,
      disabled: true,
    },
  ];
  openDialog(header) {
    this.itemHeader = header;
    this.dialog = true;
  }
  async loadData() {
    this.path = this.paths.heppyInvoicePlans;
  }
  async mounted() {
    this.loadData();
  }
  downloadCsv() {
    return this.items;
  }

  async downloadExcel() {
    this.itemsDownload = await this.downloadCsv();

    const headerList = this.headers.map((element) => element.title);

    if (headerList.includes("Actions")) {
      const index = headerList.indexOf("Actions");
      if (index > -1) {
        headerList.splice(index, 1);
      }
    }

    const headerListSpread = [headerList];
    const listElements = this.itemsDownload.map((element) => {
      const result = [];
      const newElement = element;

      for (const headerElement of this.headers) {
        const thisElement = headerElement.value
          .split(".")
          .reduce((a, b) => a[b], newElement);

        if (!thisElement) {
          result.push(headerElement.notFound);
        } else {
          result.push(thisElement);
        }
      }

      return result;
    });
    const rows = headerListSpread.concat(listElements);
    const workSheet = XLSX.utils.aoa_to_sheet(rows);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "relatório");
    XLSX.writeFile(workBook, "relatório" + ".xlsx");
  }
}
