





















































































































































import { Component, Prop } from "vue-property-decorator";
import { IPlan } from "../../../types/Plan";
import Request from "../../../services/request";
import { InputPopup } from "@matrixbrasil/matrix-lib-crud-frontend/src/views";
import { mask } from "vue-the-mask";
import { mapGetters } from "vuex";
import { translate } from "../../../plugins/i18n/index";
import { IUser } from "../../../types/User";
@Component({
  components: {
    InputPopup,
  },
  methods: {
    ...mapGetters({
      getUser: "auth/getUser",
    }),
  },
  directives: { mask },
})
export default class PlanInput extends Request {
  @Prop() readonly entity: IPlan;
  @Prop() readonly list: IPlan[];
  @Prop() readonly loadData: () => void;
  @Prop() readonly productId: number;
  header: string = translate("group");
  dialog: boolean = false;
  steper: number = 1;
  getUser: () => IUser;
  localEntity: any = {};
  editorUserId: number = null;
  channelList: any = [];
  contactTypeList = [
    { id: 1, name: translate("import") },
    { id: 2, name: translate("contactList") },
    { id: 3, name: translate("integration") },
  ];
  flowList = [
    { id: 1, name: translate("flow1") },
    { id: 2, name: translate("flow2") },
    { id: 3, name: translate("flow3") },
  ];
  load(): void {
    if (this.entity) {
      this.localEntity = { ...this.entity };
    }
  }

  async onSave() {
    this.loadData();
  }

  async mounted() {
    this.load();
    const channelList = await this.get(this.paths.channelPath);
    this.channelList = channelList[0];
  }
}
