var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c('v-dialog',{attrs:{"max-width":"80%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.entity && _vm.entity.id)?_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","title":"$t('button.edit')"}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")]):_c('v-btn',_vm._g(_vm._b({staticClass:"v-btn__content",attrs:{"color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","title":"$t('create')"}},[_vm._v(" mdi-plus ")]),_vm._v(" "+_vm._s(_vm.$t("button.add"))+" ")],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-toolbar',{staticClass:"mb-3",attrs:{"color":"primary","dark":""}},[_vm._v(" "+_vm._s(_vm.entity.id ? _vm.$t("edit") + _vm.header : _vm.$t("add") + _vm.header)+" "),_c('v-spacer'),_c('v-spacer'),_c('v-icon',{on:{"click":function($event){_vm.dialog = false}}},[_vm._v("mdi-close")])],1),_c('v-card',[_c('v-stepper',{model:{value:(_vm.steper),callback:function ($$v) {_vm.steper=$$v},expression:"steper"}},[_c('v-stepper-header',[_c('v-stepper-step',{attrs:{"complete":_vm.steper > 1,"step":"1"}},[_vm._v(" "+_vm._s(_vm.$t("contactGroup"))+" ")]),_c('v-divider'),_c('v-stepper-step',{attrs:{"complete":_vm.steper > 2,"step":"2"}},[_vm._v(" "+_vm._s(_vm.$t("channelType"))+" ")]),_c('v-divider'),_c('v-stepper-step',{attrs:{"complete":_vm.steper > 3,"step":"3"}},[_vm._v(" "+_vm._s(_vm.$t("flow"))+" ")])],1),_c('v-stepper-items',[_c('v-stepper-content',{attrs:{"step":"1"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"outlined":"","items":_vm.contactTypeList,"label":_vm.$t('contactGroup'),"placeholder":_vm.$t('contactGroup'),"item-text":"name","return-object":true,"item-value":"id"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}}])})],1)],1),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.steper = 2}}},[_vm._v(" "+_vm._s(_vm.$t("continue"))+" ")]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" "+_vm._s(_vm.$t("button.cancel"))+" ")])],1),_c('v-stepper-content',{attrs:{"step":"2"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"outlined":"","items":_vm.channelList,"label":_vm.$t('attendancyChannel'),"placeholder":_vm.$t('attendancyChannel'),"item-text":"name","return-object":true,"item-value":"id"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}}])})],1)],1),_c('v-btn',{staticClass:"mr-3",attrs:{"color":"primary"},on:{"click":function($event){_vm.steper = 1}}},[_vm._v(" "+_vm._s(_vm.$t("button.back"))+" ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.steper = 3}}},[_vm._v(" "+_vm._s(_vm.$t("continue"))+" ")]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" "+_vm._s(_vm.$t("button.cancel"))+" ")])],1),_c('v-stepper-content',{attrs:{"step":"3"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"outlined":"","items":_vm.flowList,"label":_vm.$t('flow'),"placeholder":_vm.$t('flow'),"item-text":"name","return-object":true,"item-value":"id"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}}])})],1)],1),_c('v-btn',{staticClass:"mr-3",attrs:{"color":"primary"},on:{"click":function($event){_vm.steper = 2}}},[_vm._v(" "+_vm._s(_vm.$t("button.back"))+" ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.steper = 4}}},[_vm._v(" "+_vm._s(_vm.$t("shoot"))+" ")]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" "+_vm._s(_vm.$t("button.cancel"))+" ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }